import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["carousel", "carouselItem"]
  // var loadedImagesOffset = 2
  // static LOADED_IMAGES_OFFSET = 2

  connect() {
    this.INITIAL_AMOUNT_OF_LOADED_NEIGHBOURS = 1
    this.SLIDE_AMOUNT_OF_LOADED_NEIGHBOURS = 2

    this.carouselTarget.addEventListener('slide.bs.carousel', this.carouselSlide.bind(this))
    this._loadWithNeighbours(0, this.INITIAL_AMOUNT_OF_LOADED_NEIGHBOURS)
  }

  carouselSlide({ to: imageToLoad }) {
    this._loadWithNeighbours(imageToLoad, this.SLIDE_AMOUNT_OF_LOADED_NEIGHBOURS)
  }

  // Loads image from the given index, together with neighbouring elements.
  //
  // Arguments:
  //  - imageIndex - index of the image that is being loaded and made visible
  //  - neighboursEachSide - how many neighouring elements (from each side) should be preloaded
  //
  // For image at the index 5 and neighboursEachSide set to 2,
  // images at indexes [3, 4, 5, 6, 7] will be loaded.
  //
  // It also takes into consideration an infite scroll of the carousel.
  // For 10 item (max index 9), index 0 and neighboursEachSide set to 2,
  // images at indexes [8, 9, 0, 1, 2] will be loaded.
  //
  // Example:
  //  _loadWithNeighbours(5, 2):
  //    - creates an array of indexes to load, with the imageIndex the first element in the array: [5, 4, 6, 3, 7]
  //    - preloads the images at these indexes.
  _loadWithNeighbours(imageIndex, neighboursEachSide) {
    var indexes = [imageIndex]

    for(var i = 1; i <= neighboursEachSide ; i++){
      var next = this._modulo((imageIndex + i), this.carouselItemTargets.length)
      var prev = this._modulo((imageIndex - i), this.carouselItemTargets.length)
      indexes.push(next, prev)
    }

    indexes.forEach(this._lazyLoadImage.bind(this))
  }

  _lazyLoadImage(index) {
    if(index >= 0 && index < this.carouselItemTargets.length) {
      var imageToLoad = this.carouselItemTargets[index]
      if(imageToLoad.getElementsByTagName('img').length == 0) {
        imageToLoad.innerHTML = imageToLoad.dataset.lazyLoadImg
      }
    }
  }

  // n - dividend
  // d - divisor
  _modulo(n, d) {
    return ((n % d) + d) % d
  }
}
