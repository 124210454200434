import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="location"
export default class extends Controller {
  static targets = ['link']

  copy_link(event) {
    event.preventDefault()
    try {
      let link = this.value
      if(link === undefined) throw 'Undefined'

      navigator.clipboard.writeText(link).then(function(x) {
        alert('Link copied to clipboard: ' + link);
      });
    } catch (e) {
      alert('Error while doing the operation.')
    }
  }

  get value(){
    return this.linkTarget.href
  }
}
